

.contentPolitiqueConfidentialite {
  padding-top: 20px;
  background-color: #FFFFFF;
  padding-left: 140px;
  padding-right: 140px;
  padding-bottom: 140px;
  zoom: 0.85;

  .title {
    font-size: 35px;
    margin-top: 60px;
    margin-bottom: 0px;
    letter-spacing: 0.6px;
    font-weight: 300;
  }

  .barTitle {
    height: 0px;
    background-color: #7085de;
    margin-top: 10px;
    border-radius: 20px;
  }

  .corps {
    font-weight: 300;
    word-spacing: 2px;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.6px;
  }

  .numeros {

    .numero {
      font-size: 30px;
      font-weight: 500;
      color: #273F57;
      margin-left: 25px;
    }

    .barBleue {
      width: 5px;
      height: 40px;
      background-color: #7085de;
      border-radius: 20px;
      margin-left: 15px;
      margin-right: 40px;
    }
  }

  .subtitleValeurs {
    font-size: 25px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 50px;
  }


}

