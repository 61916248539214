
.containerDefilant {
  .defilant {
    width: 100vw;
    overflow: hidden;
    display: flex;
    margin-top: 80px;

    .animateMarginLeft {
      transition: margin-left 0.3s ease-in-out;
      -webkit-font-smoothing: antialiased;
    }

    .partieDefilant {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 800px;
      margin-left: 20px;
      margin-bottom: 0px;

      .imageDefilant {
        margin-top: 40px;
        width: 800px;
        object-fit: contain;
      }

      .title {
        margin-top: 35px;
        font-size: 18px;
        font-weight: 600;
        transition: opacity 0.5s ease-in-out;
      }

      .subtitle {
        margin-top: -5px;
        text-align: center;
        font-weight: 400;
        line-height: 22px;
        transition: opacity 0.5s ease-in-out;
      }
    }
  }

  .troisPetitsPoints {
    width: 100vw;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .point:hover {
      background-color: #cccccc;
    }

    .point {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 8px;
      height: 8px;
      border-radius: 15px;
      background-color: #eeeeee;
      margin-right: 10px;
      cursor: pointer;
    }
  }

}
