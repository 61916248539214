.firstPartHomePage {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;

  .mainPicture {
    width: calc(100vw - 60px);
    border-radius: 15px;
    height: calc(100vh - 130px);
    object-fit: cover;
    display: flex;
    position: relative;
    z-index: 1;
    margin-top: 20px;
    max-width: 1800px;

  }

  .filterBlack {
    width: calc(100vw - 60px);
    border-radius: 15px;
    height: calc(100vh - 130px);
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    align-items: center;
    margin-top: calc(-100vh + 130px);
    z-index: 10;
    max-width: 1800px;

    .designant {
      margin-left: 70px;
      font-size: 25px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: -50px;
    }

    .titleWhite {
      font-family: "Helvetica Neue";
      color: #FFFFFF;
      margin-left: 70px;
      font-size: 70px;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -4px;
      transition: margin-top ease-in-out 0.8s;
      width: 650px;
      max-width: 85vw;
    }

    .littleTitleWhite {
      font-family: "Helvetica Neue";
      color: #FFFFFF;
      margin-left: 70px;
      font-size: 70px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -4px;
      transition: margin-top ease-in-out 0.8s;
      width: 650px;
      max-width: 80vw;
    }

  }



  .littleTitle {
    margin-top: 76px;
    font-size: 15px;
    font-weight: 700;
    color: #4B5FB0;
  }

  .title {
    margin-top: 10px;
    font-size: 43px;
    line-height: 58px;
    font-weight: 700;
    color: #4B5FB0;
    text-align: center;
  }

  .iphoneProjet1 {
    width: 250px;
    object-fit: contain;
  }

  .iphoneProjet2 {
    width: 300px;
    object-fit: contain;
  }

  .iphoneProjet3 {
    width: 250px;
    object-fit: contain;
  }
}

.testimonies {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  .logo {
    width: 500px;
    object-fit: contain;
    max-width: 85vw;
  }

  .testimony {
    margin-top: 0px;
    text-align: center;
    font-size: 19px;
    line-height: 29px;
    width: 600px;
    max-width: 85vw;
    color: #465364;
  }

  .points {

    display: flex;
    margin-top: 30px;

    .point {
      width: 9px;
      height: 9px;
      border-radius: 9px;
      background-color: #000000;
      margin-left: 4px;
      margin-right: 4px;
      cursor: pointer;
    }
  }
}

.homePageSecondPart {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;

  .textPart {
    width: 570px;
    max-width: 85vw;
    display: flex;
    flex-direction: column;

    .titleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 70px;
      font-weight: 600;
      letter-spacing: -4px;
      width: 500px;
      max-width: 85vw;
      margin-bottom: 20px;
    }

    .littleTitleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: -3px;
      width: 500px;
      max-width: 85vw;
      margin-bottom: 20px;
      line-height: 55px;
    }

    .checksBleu {
      margin-top: 50px;
      margin-bottom: 70px;

      p {
        margin-left: 20px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .littleCorps {
      font-size: 21px;
      line-height: 33px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      width: 500px;
      max-width: 85vw;

    }

  }

  .iconsPart {

    .opusWithIcons {
      width: 570px;
      max-width: 85vw;
      object-fit: contain;
      animation: bounce 4s ease-in-out infinite;

    }

    @keyframes bounce {
      0%, 100% {
        transform: translateY(-20px);
      }
      50% {
        transform: translateY(0px);
      }
    }
  }

  .iPhoneWithLaptop {
    width: 2500px;
  }
}

.homePageThirdPart {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  margin-top: 100px;
  background-color: #F6F9FC;
  width: 100vw;

  .textPart {
    display: flex;
    max-width: 85vw;

    .planSendImage {
      width: 400px;
      max-width: 85vw;
      object-fit: contain;
    }


    .titleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 70px;
      font-weight: 600;
      letter-spacing: -4px;
      max-width: 85vw;
      margin-bottom: 20px;
    }

    .littleTitleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: -3px;
      width: 500px;
      max-width: 85vw;
      margin-bottom: 20px;
      line-height: 55px;
    }

    .xFois {

      img {
        width: 140px;
        object-fit: contain;
      }

      h2 {
        font-size: 47px;
        font-weight: 700;
        color: #5c70f2;
        margin-bottom: 0;
        margin-top: 17px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
        color: #000000;
        white-space: nowrap;
      }
    }

    .barBleue {
      margin-top: -15px;
      height: 230px;
      background-color: rgba(79, 102, 251, 0.75);
      width: 3px;
      border-radius: 20px;
      margin-left: 18px;
      margin-right: 18px;
    }


    .horizontalBarBleue {
      width: 85vw;
      background-color: rgba(79, 102, 251, 0.75);
      height: 3px;
      border-radius: 50px;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .unusefulComFr {
      width: 260px;
      margin-top: 30px;
      margin-left: 340px;
    }

    .littleCorps {
      font-size: 21px;
      line-height: 33px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      width: 500px;
      max-width: 85vw;

    }

  }

  .iconsPart {

    .opusWithAvatar {
      width: 500px;
      max-width: 80vw;
    }

  }

  .intervenant {
    margin-top: 150px;
    width: 1050px;
    max-width: 90vw;
  }

  .clientPicture {
    margin-top: 60px;
    width: 1125px;
    max-width: 90vw;
  }

  .textPicture {

    width: 520px;
    max-width: 85vw;

    .titleTextPicture {
      margin-top: 35px;
      font-weight: 700;
      font-size: 17px;
    }

    .subtitleTextPicture {
      font-size: 16px;
      line-height: 25px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 0px;
      width: 520px;
      max-width: 85vw;
    }

    .littleTitleTextPicture {
      margin-top: 35px;
      font-weight: 700;
      font-size: 20px;
    }

    .littleSubtitleTextPicture {
      font-size: 19px;
      line-height: 31px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 10px;
      width: 520px;
      max-width: 85vw;
    }
  }
}

.transmettreInformation {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  margin-top: 100px;
  width: 100vw;



  .titleBlack {
    font-family: "Helvetica Neue";
    color: #10243F;
    font-size: 70px;
    font-weight: 600;
    letter-spacing: -4px;
    max-width: 85vw;
    margin-bottom: 20px;
  }

  .littleTitleBlack {
    font-family: "Helvetica Neue";
    color: #10243F;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: -3px;
    width: 500px;
    max-width: 95vw;
    margin-bottom: 20px;
    line-height: 55px;
  }

  .xFois {

    img {
      width: 140px;
      object-fit: contain;
    }

    h2 {
      font-size: 47px;
      font-weight: 700;
      color: #5c70f2;
      margin-bottom: 0;
      margin-top: 17px;
    }

    p {
      font-size: 15px;
      font-weight: 600;
      color: #5c70f2;
      white-space: nowrap;
    }


    .barBleue {
      margin-top: -15px;
      height: 230px;
      background-color: rgba(79, 102, 251, 0.75);
      width: 3px;
      border-radius: 20px;
      margin-left: 18px;
      margin-right: 18px;
    }

    .unusefulComFr {
      width: 260px;
      margin-top: 30px;
      margin-left: 360px;
    }

    .littleCorps {
      font-size: 21px;
      line-height: 33px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      width: 500px;
      max-width: 85vw;

    }

  }

  .iconsPart {

    .opusWithAvatar {
      width: 500px;
      max-width: 80vw;
    }

  }

  .intervenant {
    margin-top: 150px;
    width: 1050px;
    max-width: 90vw;
  }

  .clientPicture {
    margin-top: 60px;
    width: 1125px;
    max-width: 90vw;
  }

  .textPicture {

    width: 520px;
    max-width: 85vw;

    .titleTextPicture {
      margin-top: 35px;
      font-weight: 700;
      font-size: 17px;
    }

    .subtitleTextPicture {
      font-size: 16px;
      line-height: 25px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 0px;
      width: 520px;
      max-width: 85vw;
    }

    .littleTitleTextPicture {
      margin-top: 35px;
      font-weight: 700;
      font-size: 20px;
    }

    .littleSubtitleTextPicture {
      font-size: 19px;
      line-height: 31px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 10px;
      width: 520px;
      max-width: 85vw;
    }
  }
}

.fourthPartHomePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
  background-color: #10243F;

  .titleBlack {
    font-family: "Helvetica Neue";
    color: #10243F;
    font-size: 70px;
    font-weight: 600;
    letter-spacing: -4px;
    line-height: 70px;
    width: 500px;
    max-width: 85vw;
    margin-bottom: 40px;
  }

  .littleTitleBlack {
    font-family: "Helvetica Neue";
    color: #10243F;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: -3px;
    width: 500px;
    max-width: 90vw;
    margin-bottom: 20px;
    line-height: 55px;
  }

  .corps {
    font-size: 18px;
    line-height: 28px;
    color: #465364;
    font-weight: 500;
    margin-bottom: 40px;
    width: 500px;
    max-width: 85vw;

  }

  .littleCorps {
    font-size: 21px;
    line-height: 33px;
    color: #465364;
    font-weight: 500;
    margin-bottom: 40px;
    width: 500px;
    max-width: 85vw;

  }

  .iPhoneInformations {
    width: 500px;
    max-width: 100vw;
    margin-right: -100px;
    object-fit: contain;
    margin-top: -40px;
    margin-left: -40px;
  }

  .bigEarth {
    width: 900px;
    margin-right: -400px;
    object-fit: contain;
    margin-top: 100px;
    margin-left: -250px;
    opacity: 0.5;
  }

  .earth {
    width: 700px;
    margin-right: -250px;
    object-fit: contain;
    margin-top: 100px;
    margin-left: 120px;
    opacity: 0.5;
  }

  .chiffre {
    margin-left: 10px;
    margin-right: 10px;


    .traitBleu {
      margin-top: 5px;
      height: 23px;
      width: 2px;
      background-color: #51D0FD;
    }

    .chiffreText {
      margin-left: 15px;
      margin-top: 0px;
      font-size: 27px;
      margin-bottom: 0px;
      color: #FFFFFF;
      font-weight: 700;
      font-family: "Helvetica Neue";
    }

    .chiffreDescription {
      margin-left: 15px;
      color: #cfd7e0;
      width: 280px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 40px;
      max-width: 85vw;

    }
  }

  .graphiqueConfiance {
    margin-top: 40px;
    width: 1300px;
    max-width: 90vw;
  }

  .traitBleu {
    margin-top: 5px;
    height: 23px;
    width: 2px;
    background-color: #51D0FD;
  }

  .chiffreText {
    margin-left: 15px;
    margin-top: 0px;
    font-size: 27px;
    margin-bottom: 0px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: "Helvetica Neue";
  }


  .threeElements {
    width: 550px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;

    .photoTree {
      width: 550px;
      height: 400px;
      object-fit: cover;
    }

    .littleTitle {
      font-size: 18px;
      margin-bottom: 0px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .littleCorps {
      opacity: 0.7;
      line-height: 24px;
      color: #F8F8F8;
      font-size: 15px;
      width: 500px;

    }
  }
}


.pourquoiChoisirOpus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100vw;


  .littleTitle {
    margin-top: 0px;
    color: #5f74fc;
    font-weight: 700;
    font-size: 17px;
  }

  .title {
    margin-top: 0px;
    font-size: 33px;
    width: 700px;
    font-weight: 600;
    max-width: 90vw;
  }

  .point {

    width: 280px;

    .icon {
      object-fit: contain;
      margin-bottom: 25px;
      margin-left: 15px;
    }

    .barBleue {
      margin-top: 0px;
      height: 18px;
      width: 2px;
      background-color: #5f74fc;
    }

    .titlePoint {
      margin-left: 15px;
      margin-top: 0px;
      font-weight: 600;
      margin-bottom: 0px;
      width: 250px;
      font-size: 16px;
    }


    .subtitlePoint {
      margin-left: 15px;
      color: #4C596A;
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
      margin-bottom: 70px;
      width: 230px;
      max-width: 85vw;

    }

    .littleTitlePoint {
      margin-left: 15px;
      margin-top: -4px;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 0px;
    }

    .littleSubtitlePoint {
      margin-left: 15px;
      font-size: 19px;
      line-height: 31px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 85px;
      width: 520px;
      max-width: 85vw;
    }
  }

}

.PretAVousLancer {

  margin-bottom: 150px;
  margin-top: 150px;
  display: flex;
  max-width: 100vw;

  .firstPart {
    max-width: 85vw;

    .title {
      font-size: 24px;
      font-weight: 700;
    }

    .description {
      font-size: 18px;
      line-height: 30px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 30px;
      width: 450px;
      margin-right: 50px;
      max-width: 85vw;
    }

  }

  .secondThirdPart {
    margin-left: 60px;

    .img {
      height: 38px;
    }

    .title {
      width: 250px;
      font-weight: 700;
      line-height: 25px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 25px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 0px;
      width: 250px;
      max-width: 85vw;
    }

    .buttonBleu {
      font-weight: 600;
      color: #5f74fc;
      font-size: 15px;

    }

    .littleButtonBleu {
      font-weight: 600;
      color: #5f74fc;
      font-size: 17px;

    }

    .leftBleu {
      margin-left: 7px;
      margin-top: 16px;
      width: 7px;
      height: 17px;
    }


    .littleLeftBleu {
      margin-left: 7px;
      margin-top: 20px;
      width: 12px;
      height: 16px;
    }
  }

}

.pourAllerPlusLoin {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;

  .title {
    margin-top: 130px;
    font-size: 24px;
    font-weight: 700;
    width: 250px;
    line-height: 25px;
  }

  .corps {
    font-size: 18px;
    line-height: 28px;
    color: #465364;
    font-weight: 500;
    margin-bottom: 40px;
    width: 500px;
    max-width: 85vw;
    text-align: center;
  }

  .lien {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    .img {
      width: 600px;
      border-radius: 20px;
      height: 270px;

    }

    .textPart {
      display: flex;
      background-color: rgba(0, 0, 0, 0.33);
      width: 600px;
      max-width: 90vw;
      border-radius: 20px;
      height: 270px;
      flex-direction: column;
      justify-content: center;
      position: absolute;

      .titleLien {
        color: #FFFFFF;
        margin-left: 35px;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 0px;
        margin-top: 0px;
      }

      .subtitleLien {
        font-size: 17px;
        color: #FFFFFF;
        margin-left: 35px;
        width: 280px;
        font-weight: 600;
        line-height: 26px;
      }

      .enSavoirPlus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .enSavoirPlus {

        border: solid 2px #FFFFFF;
        border-radius: 30px;
        width: 140px;
        margin-left: 35px;
        font-size: 15px;
        display: flex;
        color: #FFFFFF;
        font-weight: 600;
        height: 30px;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s;

        .leftBlanc {
          width: 6px;
          margin-left: 7px;
        }
      }
    }

  }
}

.nouvelleDimension {

  .nouvelleDimensionImage {
    width: 100vw;
    height: 700px;
    object-fit: cover;
  }

  .filtreNoir {
    width: 100vw;
    height: 700px;
    display: flex;
    position: absolute;


    .title {
      font-family: "Helvetica Neue";
      color: #ffffff;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: -2.5px;
      max-width: 85vw;
      margin-bottom: 20px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .corps {
      margin-top: 0px;
      width: 600px;
      color: #FFFFFF;
      font-size: 20px;
      line-height: 27px;
      font-weight: 400;
      max-width: 85vw;

    }

    .boutonDecouvrir:hover {
      opacity: 0.7;
    }

    .boutonDecouvrir {
      margin-top: 15px;
      display: flex;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.29);
      padding: 13px 20px;
      border-radius: 50px;
      transition: opacity ease-in-out 0.2s;
      cursor: pointer;
    }
  }
}

.offerYourClient {
  display: flex;
  background-color: #F4F8FC;
  width: 100vw;
  justify-content: center;

  .leftPart {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-family: "Helvetica Neue";
      color: #000000;
      font-size: 45px;
      font-weight: 600;
      letter-spacing: -2.5px;
      max-width: 85vw;
      margin-bottom: 25px;
      margin-top: 0;
    }

    .bar {
      width: 100%;
      height: 3px;
      border-radius: 10px;

    }

    .degradeOfferClientLeft {
      margin-top: -15px;
      display: flex;
      left: 0;
      width: 50vw;
      height: 118px;
      position: absolute;
      opacity: 0.7;
      background-image: linear-gradient(to right, #CBFCCD, rgba(208, 234, 252, 0.87) 50%, rgba(49, 49, 49, 0));
      animation: fade 3s ease-in-out infinite;
      transition: opacity 0.5s ease-in-out;
    }

    @keyframes fade {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    .degradeOfferClientRight {
      margin-top: -15px;
      display: flex;
      right: 0;
      width: 50vw;
      height: 118px;
      position: absolute;
      opacity: 0.7;
      background-image: linear-gradient(to right, #CBFCCD, rgba(208, 234, 252, 0.87) 50%, rgba(49, 49, 49, 0));
      animation: fade 3s ease-in-out infinite;
      transition: opacity 0.5s ease-in-out;

    }

    .corps {
      margin-top: 25px;
      font-size: 18px;
      max-width: 85vw;
      width: 600px;

    }
  }


  .rightPart:hover {
    .hoverPhoto {
      .offerClientInfography {
        margin-bottom: 110px;
      }
    }

    .offerClientPhoto {
      transform: scale(1.02);

    }
  }


  .photoPart {
    width: 50vw;
    height: 750px;
    overflow: hidden;

    .hoverPhoto {
      position: absolute;
      display: flex;
      height: 750px;
      align-items: flex-end;
      z-index: 10;

      .offerClientInfography {
        width: 50vw;
        object-fit: contain;
        margin-bottom: 98px;
        transition: margin-bottom 0.5s ease-in-out;
      }
    }

    .offerClientPhoto {
      z-index: 1;
      width: 50vw;
      height: 750px;
      object-fit: cover;
      transition: transform ease-in-out 0.5s;
    }
  }
}

.videoOpus {
  width: 100vw;
  height: 700px;

  .filtreNoir {
    width: 100vw;
    height: 600px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.27);

    .title {
      font-family: "Helvetica Neue";
      color: #ffffff;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: -2.5px;
      max-width: 85vw;
      margin-bottom: 20px;
      margin-top: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    .boutonDecouvrir:hover {
      opacity: 0.7;
    }

    .boutonDecouvrir {
      margin-top: 15px;
      display: flex;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.29);
      padding: 13px 20px;
      border-radius: 50px;
      transition: opacity ease-in-out 0.2s;
      cursor: pointer;
    }
  }

}

.simplissime {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  margin-top: 100px;
  width: 100vw;

  .textPart {
    display: flex;
    max-width: 85vw;

    .stepsForIntegration {
      width: 600px;
      max-width: 85vw;
      object-fit: contain;
    }

    .titleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 70px;
      font-weight: 600;
      letter-spacing: -4px;
      max-width: 85vw;
      margin-bottom: 20px;
    }

    .degrade {
      margin-top: 62px;
      display: flex;
      left: 0;
      width: calc(100vw - 800px);
      max-width: 50vw;
      height: 98px;
      position: absolute;
      opacity: 0.7;
      background-image: linear-gradient(to right, #CBFCCD, rgba(208, 234, 252, 0.87) 50%, rgba(49, 49, 49, 0));

    }

    .callToAction:hover {
      .buttonAddProject {
        transform: scale(1.05);
      }

      .freeProjectSimplissime {
        transform: translateY(-10px);
      }
    }

    .callToAction {


      .buttonAddProject {
        margin-top: 30px;
        width: 450px;
        margin-left: -30px;
        object-fit: contain;
        transition: transform 0.4s ease-in-out;
        cursor: pointer;
      }

      .freeProjectSimplissime {
        margin-top: 20px;
        width: 250px;
        object-fit: contain;
        margin-left: 250px;
        transition: transform 0.4s ease-in-out;

      }
    }



    .littleTitleBlack {
      font-family: "Helvetica Neue";
      color: #10243F;
      font-size: 50px;
      font-weight: 600;
      letter-spacing: -3px;
      width: 500px;
      max-width: 85vw;
      margin-bottom: 20px;
      line-height: 55px;
    }
    .littleCorps {
      font-size: 21px;
      line-height: 33px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      width: 500px;
      max-width: 85vw;

    }

  }


  .itemChoice {
    display: flex;
    align-items: center;

    .checkSimplissime {
      margin-right: 8px;
      height: 18px;
      object-fit: contain;

    }

    .nomItemChoice {
      font-size: 15px;
      margin: 0;
    }
  }
}

