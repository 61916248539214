.carracteristiquequeOffre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  zoom: 0.85;

  .bluePlus {
    margin-right: 5px;
    width: 19px;
    object-fit: contain;
  }

  .afficherCarracteristiques {
    font-size: 19px;
    font-weight: 500;
    color: #4B5FB0;
  }

  .tableauFoncitonnalites {
    overflow-x: hidden;
    overflow-y: hidden;
    transition: height 0.3s;
    -webkit-transition: height 0.3s;
  }

  .detailOffres {
    margin-top: 70px;

    .title {
      margin-top: 15px;
      font-size: 19px;
      font-weight: 500;
      color: #4B5FB0;
      letter-spacing: 0.3px;
      margin-bottom: 20px;
    }

    .price {
      font-size: 70px;
      font-weight: 200;
      margin-top: -10px;
    }

    .detailPrice {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 10px;
      font-size: 14px;
      color: #8c8c8c;
    }

    .secondColonne {
      margin-left: 428px;
      border-top-left-radius: 15px;
      width: 250px;
      height: 150px;
      border: solid 1px #e0e0e0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .thirdColonne {
      margin-left: -1px;
      width: 250px;
      height: 150px;
      border: solid 1px #e0e0e0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .fourthColonne {
      border-top-right-radius: 15px;
      margin-left: -1px;
      width: 250px;
      height: 150px;
      border: solid 1px #e0e0e0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .bluePart {
    margin-top: -1px;
    border: solid 1px #e0e0e0;
    border-top-left-radius: 15px;
    display: flex;
    align-items: center;
    width: 1180px;
    height: 37px;
    background-color: #F6F9FC;

    p {
      margin-left: 20px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ligneCarracteristique:hover {
    .firstPart {
      background-color: #F6F9FC;
    }

    .secondPart {
      background-color: #F6F9FC;
    }

    .thirdPart {
      background-color: #F6F9FC;
    }

    .fourthPart {
      background-color: #F6F9FC;
    }
  }

  .ligneCarracteristique {
    margin-top: -1px;

    .greenYes {
      width: 20px;
      object-fit: contain;
    }

    .textCarracteristique {
      font-size: 15px;
      margin: 0;
      letter-spacing: 0.6px;
    }

    .firstPart {
      height: 95px;
      border: solid 1px #e0e0e0;
      width: 427px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 18px;
        margin-left: 20px;
        margin-top: 0px;
        margin-bottom: 8px;
      }

      .subtitle {
        margin-left: 20px;
        font-size: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #8c8c8c;
        width: 380px;
      }
    }

    .secondPart {
      margin-left: -1px;
      height: 95px;
      border: solid 1px #e0e0e0;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .thirdPart {
      margin-left: -1px;
      height: 95px;
      border: solid 1px #e0e0e0;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fourthPart {
      margin-left: -1px;
      height: 95px;
      border: solid 1px #e0e0e0;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


.petitChoixAbonnement {

  display: flex;
  flex-direction: column;
  align-items: center;

  .choiceTime {
    margin-top: 25px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    height: 55px;
    width: 450px;
    max-width: 80vw;
    border-radius: 20px;
    display: flex;

    .mensuel {
      cursor: pointer;
      width: 225px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;

      .barBleue {
        width: 80px;
        height: 2px;
        background-color: #4555EA;
        display: flex;
        position: absolute;
        margin-top: 54px;
        border-radius: 20px;
      }
    }

    .bar {
      height: 55px;
      width: 1px;
      background-color: #dadada;
    }

    .annuel {
      cursor: pointer;
      width: 225px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      flex-direction: column;

      .barBleue {
        width: 80px;
        height: 2px;
        background-color: #4555EA;
        display: flex;
        position: absolute;
        margin-top: 54px;
        border-radius: 20px;
      }

    }
  }

  .listeOffres {
    display: flex;
    margin-top: -280px;


    .image-clignote  {
      animation-duration: 2s;
      animation-name: clignoter;
      animation-iteration-count: infinite;
      transition: none;
    }
    @keyframes clignoter {
      0%   { opacity:1; }
      40%   {opacity:0.5; }
      100% { opacity:1; }
    }

    .offre {
      width: 350px;
      height: 510px;
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;

      .titleOffre {
        margin-top: 22px;
        font-size: 19px;
        font-weight: 600;
      }

      .bar {
        width: 350px;
        height: 1px;
        background-color: #e8e8e8;
      }

      .price {
        font-weight: 200;
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 50px;
      }

      .subtitle {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .blueSubtitle {
        margin-top: 0px;
        font-size: 14px;
        color: #4555EA;
        margin-bottom: 20px;
      }

      .item {
        margin-top: 5px;
        display: flex;

        .icon {
          width: 25px;
        }

        .titleItem {
          margin-left: 15px;
          font-size: 14px;
          color: #344873;
        }
      }

      .button {
        margin-top: 20px;
        background-color: #5f74fc;
        color: #FFFFFF;
        width: 150px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .petiteOffre {
      width: 350px;
      height: 520px;
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;

      .titleOffre {
        margin-top: 22px;
        font-size: 23px;
        font-weight: 600;
      }

      .bar {
        width: 350px;
        height: 1px;
        background-color: #e8e8e8;
      }

      .price {
        font-weight: 200;
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 50px;
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 5px;
      }

      .blueSubtitle {
        margin-top: 0px;
        font-size: 16px;
        color: #4555EA;
        margin-bottom: 20px;
      }

      .item {
        margin-top: 5px;
        display: flex;

        .icon {
          width: 28px;
        }

        .titleItem {
          margin-left: 15px;
          font-size: 16px;
          color: #344873;
        }
      }

      .button {
        margin-top: -10px;
        background-color: #5f74fc;
        color: #FFFFFF;
        width: 200px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
      }

    }

    .marge {
      width: 50px;
    }
  }

  .offrePlus {
    width: 900px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    max-width: 90vw;

    .titleOffre {
      margin-top: 22px;
      font-size: 19px;
      font-weight: 600;
    }

    .bar {
      width: 900px;
      max-width: 90vw;
      height: 1px;
      background-color: #e8e8e8;
    }

    .price {
      font-weight: 200;
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 50px;
    }

    .subtitle {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .blueSubtitle {
      margin-top: 0px;
      font-size: 14px;
      color: #4555EA;
      margin-bottom: 20px;
    }

    .item {
      margin-top: 5px;
      display: flex;

      .icon {
        width: 26px;
      }

      .titleItem {
        margin-left: 15px;
        font-size: 14px;
        color: #344873;
      }
    }


    .barVertical {
      height: 200px;
      width: 1px;
      margin-top: 30px;
      background-color: #eeeeee;
    }
  }

  .petitOffrePlus {
    width: 900px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    max-width: 90vw;

    .titleOffre {
      margin-top: 22px;
      font-size: 23px;
      font-weight: 600;
    }

    .bar {
      width: 900px;
      max-width: 90vw;
      height: 1px;
      background-color: #e8e8e8;
    }

    .price {
      font-weight: 200;
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 50px;
    }

    .subtitle {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .blueSubtitle {
      margin-top: 0px;
      font-size: 16px;
      color: #4555EA;
      margin-bottom: 20px;
    }

    .item {
      margin-top: 5px;
      display: flex;

      .icon {
        width: 28px;
      }

      .titleItem {
        margin-left: 15px;
        font-size: 16px;
        color: #344873;
      }
    }


    .barVertical {
      height: 200px;
      width: 1px;
      margin-top: 30px;
      background-color: #eeeeee;
    }
  }
}
