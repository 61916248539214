.headerComponent {

  z-index: 1;
  width: 100vw;
  padding-left: 33px;

  .header {
    height: 64px;
    display: flex;
    align-items: center;

    .logo {
      font-weight: 200;
      margin: 0px;
    }

    .menu {
      width: calc(100vw - 145px);
      max-width: 1700px;
      display: flex;
      justify-content: flex-end;

      .menuItem:hover {
        opacity: 0.6;
      }

      .menuItem {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin-left: 20px;
        font-size: 15px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
      }

    }
  }

  .grandMenu {
    width: 100vw;
    background-color: rgb(255, 255, 255);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 64px;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    justify-content: center;

    .leftPart {
      width: 260px;
      margin-top: 20px;
      border-right: solid 1px #eeeeee;
      height: 700px;

      .containerElement {
        cursor: pointer;

        .title {
          font-size: 22px;
          font-weight: 700;
          margin-left: 40px;
          margin-bottom: 7px;
          opacity: 0.6;
        }

        .titleSelect {
          font-size: 22px;
          font-weight: 700;
          margin-left: 40px;
          margin-bottom: 7px;
        }

        .subtitle {
          margin-top: 0px;
          margin-left: 40px;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 50px;
          opacity: 0.6;
        }

        .subtitleSelect {
          margin-top: 0px;
          margin-left: 40px;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 50px;
        }
      }
    }

    .rightPart {
      width: calc(100vw - 350px);

      .opus {
        display: flex;

        .titleIphone {
          font-weight: 600;
          font-size: 20px;
          color: #4B5FB0;
          margin-left: 20px;
        }

        .iphone {
          margin-top: 40px;
          margin-left: 100px;
          width: 300px;
          object-fit: contain;
        }

        .web {
          width: 920px;
          margin-left: 100px;
          object-fit: contain;
          max-width: calc(100vw - 700px);
        }
      }

      .titlePart {
        margin-top: 48px;
        font-weight: 700;
        font-size: 14px;
        margin-left: 50px;
        color: #4B5FB0;
        margin-bottom: -20px;
      }

      .itemBigMenu {
        margin-left: 50px;
        width: 200px;
        margin-top: 30px;

        .title {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0px;
        }

        .subtitle {
          margin-top: 7px;
          font-weight: 400;
          font-size: 14px;
        }

        .img {
          margin-left: -21px;
          width: 200px;
          object-fit: contain;
        }
      }
    }

    .containerCross:hover {
      background-color: #f8f8f8;

    }

    .containerCross {
      margin-top: 30px;
      width: 45px;
      height: 45px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      opacity: 0.6;

      .cross {
        width: 20px;
        object-fit: contain;
      }
    }
  }

  .grandMenuFiltre {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.61);
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.3s ;
    cursor: pointer;
  }

  .menuDeDroite {
    overflow: hidden;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    transition: width 0.3s ease-in-out;

    .headerMenu {
      height: 64px;
      width: 400px;
      border-bottom: solid 1px #eeeeee;
      display: flex;
      align-items: center;
      padding-left: 20px;

      p {
        font-size: 23px;
        font-weight: 200;
        margin: 0px;
      }

      .containerRightIcon:hover {
        background-color: #f8f8f8;

      }

      .containerRightIcon {
        margin-left: -14px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;

        .rightIcon {
          width: 13px;
          object-fit: contain;
        }
      }

      .containerJeSuis {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;

        .jeSuis {
          font-size: 15px;
          font-weight: 600;
          text-align: center;
        }
      }

      .containerCross:hover {
        background-color: #f8f8f8;

      }

      .containerCross {
        margin-left: 260px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;

        .cross {
          width: 18px;
          object-fit: contain;
        }
      }

    }

    .premierMenu {
      margin-left: 0px;
      transition: margin-left 0.3s ease-in-out;
    }

    .titlePartMenuDroite {
      margin-top: 35px;
      font-weight: 600;
      font-size: 12px;
      color: #8d8d8d;
      margin-left: 25px;
      margin-bottom: 9px;
    }

    .lienMenu:hover {
      background-color: #f8f8f8;
    }

    .lienMenu {
      height: 44px;
      padding-left: 25px;
      width: 500px;
      display: flex;
      align-items: center;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;

      .titleLienMenu {
        font-weight: 700;
        font-size: 18px;
        margin: 0px;
      }

      .leftIconThin {
        width: 8.5px;
      }
    }

    .petitLienMenu:hover {
      .titleLienMenu {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    .petitLienMenu {
      height: 35px;
      padding-left: 25px;
      width: 500px;
      display: flex;
      align-items: center;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;

      .titleLienMenu {
        font-weight: 600;
        font-size: 14px;
        margin: 0px;
      }
    }

    .containerSelectLanguage:hover {
      background-color: #f8f8f8;
    }

    .containerSelectLanguage {
      margin-top: 20px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      margin-bottom: 20px;

      .selectLanguage {
        height: 65px;
        width: 350px;
        border-bottom: solid 1px #eeeeee;
        border-top: solid 1px #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: height 0.2s ease-in-out;

        .language {
          font-size: 15px;
          margin: 0;
          opacity: 0.6;
        }

      }
    }
  }
}

