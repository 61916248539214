.miniFooter {
  overflow: hidden;
  width: 100vw;
  height: 750px;
  background-color: #F6F9FC;
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .container {
    height: 800px;

    .part {
      padding-top: 100px;
      width: 200px;
      height: 60px;

      .Opus {
        margin-top: 0px;
        font-weight: 300;
        font-size: 40px;
        color: #273F57;
        letter-spacing: 2px;
        margin-bottom: 0px;
      }

      .designant {
        letter-spacing: 0.6px;
      }

      .locationIcon {
        width: 15px;
        object-fit: contain;
        margin-right: 13px;
      }

      .languageIcon {
        width: 16px;
        object-fit: contain;
        margin-right: 13px;
      }

      .rights {
        margin-top: 380px;
        font-size: 14px;
        color: #273F57;

      }

      .titleGroup {
        font-weight: 500;
        color: #273F57;
        letter-spacing: 0.5px;
      }

      .linkGroup:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      .linkGroup {
        font-weight: 400;
        color: #273F57;
        letter-spacing: 0.5px;
      }
    }
  }

}


.littleFooter {
  overflow: hidden;
  width: 100vw;
  height: 810px;
  background-color: #F6F9FC;
  display: flex;
  margin-left: 48px;

  .container {
    width: 850px;
    height: 700px;

    .part {
      padding-top: 100px;
      width: calc((100vw) / 3);
      height: 60px;

      .Opus {
        margin-top: 0px;
        font-weight: 300;
        font-size: 40px;
        color: #273F57;
        letter-spacing: 2px;
        margin-bottom: 0px;
      }

      .designant {
        letter-spacing: 0.6px;
      }

      .locationIcon {
        width: 15px;
        object-fit: contain;
        margin-right: 13px;
      }

      .languageIcon {
        width: 16px;
        object-fit: contain;
        margin-right: 13px;
      }

      .rights {
        margin-top: 380px;
        font-size: 14px;
        color: #273F57;

      }

      .titleGroup {
        font-weight: 500;
        color: #273F57;
        letter-spacing: 0.5px;
      }

      .linkGroup:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      .linkGroup {
        font-weight: 400;
        color: #273F57;
        letter-spacing: 0.5px;
      }
    }
  }

}

.middleFooter {
  overflow: hidden;
  width: 100vw;
  height: 700px;
  background-color: #F6F9FC;
  display: flex;
  margin-left: 48px;

  .container {
    width: 1200px;
    height: 700px;
    display: flex;

    .part {
      padding-top: 100px;
      width: calc((100vw) / 3 - 100px);
      height: 700px;

      .Opus {
        margin-top: 0px;
        font-weight: 300;
        font-size: 40px;
        color: #273F57;
        letter-spacing: 2px;
        margin-bottom: 0px;
      }

      .designant {
        letter-spacing: 0.6px;
      }

      .locationIcon {
        width: 15px;
        object-fit: contain;
        margin-right: 13px;
      }

      .languageIcon {
        width: 16px;
        object-fit: contain;
        margin-right: 13px;
      }

      .rights {
        margin-top: 380px;
        font-size: 14px;
        color: #273F57;

      }

      .titleGroup {
        font-weight: 500;
        color: #273F57;
        letter-spacing: 0.5px;
      }

      .linkGroup:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      .linkGroup {
        font-weight: 400;
        color: #273F57;
        letter-spacing: 0.5px;
      }
    }
  }

}

.bigFooter {
  overflow: hidden;
  width: 100vw;
  height: 430px;
  background-color: #F6F9FC;
  display: flex;
  justify-content: center;

  .container {
    width: 1200px;
    height: 400px;
    display: flex;
    padding-top: 30px;
    zoom: 0.85;

    .part {
      padding-top: 100px;
      width: 300px;
      height: 700px;

      .Opus {
        margin-top: 0px;
        font-weight: 300;
        font-size: 40px;
        color: #273F57;
        letter-spacing: 2px;
        margin-bottom: 0px;
      }

      .designant {
        letter-spacing: 0.6px;
      }

      .locationIcon {
        width: 15px;
        object-fit: contain;
        margin-right: 13px;
      }

      .languageIcon {
        width: 16px;
        object-fit: contain;
        margin-right: 13px;
      }

      .rights {
        margin-top: 200px;
        font-size: 14px;
        color: #273F57;

      }

      .titleGroup {
        font-weight: 500;
        color: #273F57;
        letter-spacing: 0.5px;
      }

      .linkGroup:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      .linkGroup {
        font-weight: 400;
        color: #273F57;
        letter-spacing: 0.5px;
      }
    }
  }
}
