.fonctionnalites {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 68px;

  .littleTitle {
    margin-top: 100px;
    font-weight: 700;
    color: #4B5FB0;
    font-size: 18px;
  }

  .bigTitle {
    margin-top: 0px;
    text-align: center;
    font-weight: 600;
    font-size: 43px;
    margin-bottom: 120px;
    width: 600px;
    max-width: 90vw;
  }

  .corps {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    opacity: 0.5;
    line-height: 30px;
    margin-top: -70px;
    width: 680px;
    max-width: 88vw;
  }

  .choiceOpus {
    margin-top: 30px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .containerNameChoice:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .containerNameChoice {
      transition: opacity 0.2s ease-in-out;
      .nameChoice {
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .bar {
      margin-top: -10px;
      height: 3px;
      border-radius: 9px;
      background-color: #4B5FB0;
      transition: all 0.3s ease-in-out;
    }
  }

  .listeFonct {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .containerPartName {
      margin-top: 40px;
      margin-bottom: 20px;
      max-width: 80vw;
      width: 80vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .titlePart {
        font-size: 25px;
        font-weight: 600;
        color: #4B5FB0;
        margin-bottom: 0px;
        text-align: center;
      }

      .subtitlePart {
        font-size: 18px;
        opacity: 0.5;
        text-align: center;
        line-height: 22px;
        width: 500px;
        max-width: 80vw;
      }
    }

    .itemFonctionnalite {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 60px;
      background-color: #F8F8F8;
      border-radius: 15px;
      width: 400px;
      max-width: 90vw;
      height: 600px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;

      .title {
        margin-top: 35px;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0px;
      }

      .subtitle {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
        margin-bottom: 30px;
      }

      .iphone {
        width: 300px;
      }

    }

    .hoverFunctionality:hover {
      .filtreNoir {
        opacity: 1;
      }
    }

    .hoverFunctionality {

      .itemFonctionnaliteWeb {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 60px;
        background-color: #F8F8F8;
        border-radius: 15px;
        width: 700px;
        max-width: 90vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        .title {
          margin-top: 35px;
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 0px;
        }

        .subtitle {
          margin-left: 30px;
          margin-right: 30px;
          text-align: center;
          margin-bottom: 30px;
        }

        .iphone {
          width: 300px;
          border-radius: 15px;
          box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
        }

        .web {
          width: 600px;
        }

        .petitWeb {
          width: 500px;
          max-width: 65vw;
        }

      }

      .filtreNoir {
        opacity: 0;
        display: flex;
        background-color: rgba(0, 0, 0, 0.43);
        width: 400px;
        max-width: 90vw;
        height: 600px;
        position: absolute;
        margin-left: 30px;
        margin-top: -600px;
        border-radius: 15px;
        transition: opacity ease-in-out 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        .enSavoirPlus:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .enSavoirPlus {
          margin-bottom: 30px;
          border: solid 2px #FFFFFF;
          border-radius: 30px;
          width: 140px;
          font-size: 15px;
          display: flex;
          color: #FFFFFF;
          font-weight: 600;
          height: 30px;
          align-items: center;
          justify-content: center;
          transition: opacity 0.2s;

          .leftBlanc {
            width: 6px;
            margin-left: 7px;
          }
        }
      }
    }

  }
}
