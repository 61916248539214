.opusResearch {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  margin-top: 68px;

  .enDev {
    background-color: #DD702C;
    color: #FFFFFF;
    padding: 5px 12px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -80px;
    margin-top: 80px;

  }

  .littleTitle {
    margin-top: 100px;
    font-weight: 700;
    color: #4B5FB0;
    font-size: 18px;
  }

  .bigTitle {
    margin-top: 0px;
    text-align: center;
    font-weight: 600;
    font-size: 43px;
    margin-bottom: 120px;
  }

  .corpsPage {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    opacity: 0.5;
    line-height: 30px;
    margin-top: -70px;
    width: 600px;
    max-width: 88vw;
  }

}

.troisImage {

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 400px;
    margin-left: 20px;
    margin-right: 20px;

    .img {
      width: 400px;
      object-fit: contain;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
    }

    .corpsImage {
      font-size: 15px;
      text-align: justify;
      font-weight: 400;
      opacity: 0.7;
      line-height: 22px;
    }

  }

}
