.legal {

  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .littleTitle {
    margin-top: 120px;
    font-size: 15px;
  }

  .title {
    margin-top: 0px;
    font-size: 30px;
    font-weight: 600;
  }

  .miseAJour {
    font-size: 13px;
    opacity: 0.6;
  }
}
