
.aPropoDeNous {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  overflow: hidden;

  .headerAPropos {
    padding-top: 69px;
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #f3faff;

    .textPart {
      width: calc(50vw - 150px);

      .littleTitle {
        margin-top: 75px;
        font-weight: 700;
        letter-spacing: -0.3px;
        color: #4B5FB0;
        font-size: 14px;
      }

      .bigTitle {
        margin-top: 0px;
        font-weight: 700;
        font-size: 34px;
        width: 550px;
        margin-bottom: 20px;
      }

      .corpsPage {
        font-size: 21px;
        font-weight: 500;
        opacity: 0.6;
        line-height: 30px;
        width: 600px;
        max-width: 88vw;
      }
    }

    .imagePart {
      width: calc(50vw - 150px);
      display: flex;
      justify-content: flex-end;

      .imageAPropos {
        width: 500px;
        object-fit: contain;
      }
    }
  }

  .notreMission {
    display: flex;
    flex-direction: column;
    align-items: center;

    .littleTitle {
      margin-top: 75px;
      font-weight: 700;
      letter-spacing: -0.3px;
      color: #4B5FB0;
      font-size: 14px;
    }

    .title {
      margin-top: 0px;
      font-weight: 600;
      font-size: 30px;
      width: 550px;
      margin-bottom: 20px;
      text-align: center;
    }

    .corpsPage {
      font-size: 18px;
      font-weight: 500;
      opacity: 0.6;
      line-height: 30px;
      width: 840px;
      max-width: 88vw;
      text-align: center;
    }
  }

}

.troisImage {

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 400px;
    margin-left: 20px;
    margin-right: 20px;

    .img {
      width: 400px;
      object-fit: contain;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
    }

    .corpsImage {
      font-size: 15px;
      text-align: justify;
      font-weight: 400;
      opacity: 0.7;
      line-height: 22px;
    }
  }
}

.nosFondateurs {
  background-color: #F6F9FC;
  margin-top: 150px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleNosFondateurs {
    margin-top: 70px;
    margin-bottom: 80px;
    font-size: 28px;
    font-weight: 700;
  }

  .itemFondateur {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin-bottom: 100px;

    .profilPicture {
      width: 250px;
      height: 250px;
      border-radius: 250px;
      object-fit: cover;
    }

    .nameFondateur {
      font-size: 20px;
      font-weight: 700;
      margin-top: 25px;
      margin-bottom: 0px;
    }

    .posteFondateur {
      font-weight: 600;
      font-size: 14px;
    }

    .descriptionFondateurs {
      margin-top: 30px;
      font-size: 15px;
      opacity: 0.7;
      text-align: justify;
      line-height: 25px;
      max-width: 90vw;
    }

  }


}
