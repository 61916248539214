.largePhoto {
  width: 100vw;
  height: 584px;
  object-fit: cover;
}

.middlePhoto {
  width: 85vw;
  object-fit: contain;
}
