.ambitionWidget {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  .ambitionPic {
    height: 650px;
    object-fit: cover;
    width: calc(100vw - 40px);
    max-width: 1500px;
    border-radius: 15px;
    margin-top: -650px;
  }

  .blackFilter {
    height: 650px;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 15px;
    display: flex;
    align-items: center;
    max-width: 1500px;

    .titleWhite {
      margin-top: 0px;
      font-family: "Helvetica Neue";
      color: #FFFFFF;
      font-size: 70px;
      font-weight: 600;
      line-height: 70px;
      letter-spacing: -4px;
      transition: all ease-in-out 0.8s;
      margin-bottom: 60px;
    }

    .corpsWhite {
      font-size: 16px;
      line-height: 23px;
      color: #FFF;
      font-weight: 600;
      text-align: center;
    }

    .button:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .button {
      width: 120px;
      padding-left: 13px;
      padding-right: 13px;
      height: 30px;
      border: solid 2px #FFFFFF;
      border-radius: 100px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 16px;
      transition: opacity ease-in-out 0.2s ;
    }
  }
}
