.largePhotoText {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;

  .title {
    margin-top: 100px;
    text-align: center;
    font-weight: 600;
    font-size: 33px;
    margin-bottom: 15px;
    margin-left: 60px;
    margin-right: 60px;
  }

  .subtitle {
    margin-top: 0px;
    font-weight: 700;
    color: #4B5FB0;
    font-size: 16px;
    margin-bottom: 70px;
  }

  .photo {
    width: calc(100vw - 200px);
    object-fit: contain;
  }

  .containerTextParts {
    display: flex;
    align-items: center;
    justify-content: center;
    .textPart {
      width: calc((100vw - 300px)/3);
      min-width: 400px;

      .titleText {
        margin-top: 30px;
        font-weight: 600;
      }

      .littleTitleText {
        margin-top: 35px;
        font-weight: 700;
        font-size: 20px;
      }

      .corps {
        width: calc((100vw - 320px)/3);
        min-width: 400px;
        margin-top: 1px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0.6;
        text-align: justify;
      }

      .littleCorps {
        font-size: 19px;
        line-height: 31px;
        color: #465364;
        font-weight: 500;
        margin-bottom: 10px;
        width: 520px;
        max-width: 85vw;
      }
    }
  }
}
