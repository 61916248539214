.contactPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 58px);
  overscroll-behavior: none;


  .textPart {

    .title {
      font-family: "Helvetica Neue";
      font-weight: 600;
      font-size: 45px;
      opacity: 0.9;
      margin-top: 35px;
    }
  }

  .secondThirdPart {

    .img {
      height: 38px;
    }

    .titlePart {
      width: 250px;
      font-weight: 700;
      line-height: 25px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 25px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 0px;
      width: 250px;
      max-width: 85vw;
    }

    .buttonBleu {
      font-weight: 600;
      color: #5f74fc;
      font-size: 15px;

    }

    .littleButtonBleu {
      font-weight: 600;
      color: #5f74fc;
      font-size: 17px;

    }

    .leftBleu {
      margin-left: 7px;
      margin-top: 16px;
      width: 7px;
      height: 17px;
    }


    .littleLeftBleu {
      margin-left: 7px;
      margin-top: 20px;
      width: 12px;
      height: 16px;
    }
  }

  .formPart {
    margin-top: 25px;
    width: 550px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    margin-left: 120px;
    background-color: #FFFFFF;
    margin-bottom: -140px;

    .formItem {
      display: flex;
      height: 45px;
      margin-top: 15px;

      .designant {
        font-size: 15px;
        font-weight: 600;
        width: 200px;
        padding-left: 20px;
        height: 25px;
        padding-right: 20px;
      }

      .input {
        background-color: #F1F4F7;
        border: solid 0px;
        height: 35px;
        border-radius: 5px;
        width: 275px;
        font-size: 15px;
        font-weight: 500;
        padding-left: 15px;
        outline: none;
      }

      .select {
        background-color: #F1F4F7;
        border: solid 0px;
        height: 35px;
        border-radius: 5px;
        width: 291px;
        font-size: 15px;
        font-weight: 500;
        padding-left: 15px;
        outline: none;
        appearance: none;
      }
      .bottomBlackFleche {
        width: 14px;
        margin-left: -25px;
      }
    }
  }

  .transitionFAQ {
    margin-top: 100px;
    width: calc(100vw - 50px) ;
    max-width: 90vw;
    height: 200px;
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .titleTransitionFAQ {
      margin: 0px;
      font-size: 33px;
      font-weight: 600;
    }

    .subtitle {
      opacity: 0.8;
      margin-bottom: 0px;
    }
  }

}

.modalContact {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 400px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 400px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #4B5FB0;
    margin-bottom: 10px;
    text-align: center;
  }

  .rectangleGris {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 100px;
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 30px;
  }
}

.questionReponse {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;

  .chiffrePart {
    display: flex;
    margin-left: 40px;
    zoom: 0.85;

    .chiffre {
      font-size: 35px;
      margin: 2px;
    }

    .barBleue {
      margin-top: 2.5px;
      margin-left: 10px;
      margin-right: 100px;
      width: 4px;
      border-radius: 20px;
      height: 45px;
      background-color: #4B5FB0;

    }
  }

  .colonneQuestionReponse {

    width: 600px;
    max-width: 80vw;
    zoom: 0.85;

    .titrePartie {
      margin-top: 8px;
      font-size: 28px;
      font-weight: 500;
      width: 450px;
      letter-spacing: 0.6px;
      margin-bottom: 45px;
    }

    .questionPosee {
      font-size: 19px;
      width: 500px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    .reponseQuestion {
      font-size: 18px;
      line-height: 28px;
      width: 450px;
      font-weight: 400;
      color: #6E747A;
      letter-spacing: 0.4px;
      margin-bottom: 50px;
    }
  }

}
.liensApplications {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF;
  height: 500px;

  .textBleu {
    color: #4B5FB0;
    font-weight: 500;
    letter-spacing: 0.6px;

  }

  .title {
    margin-top: 10px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1.3px;
  }

  .opusVersions {
    width: 400px;
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    zoom: 0.85;

    .bluePhoneIcon {
      width: 40px;
      object-fit: contain;
      margin-right: 10px;
    }

    .blueLaptopIcon {
      width: 40px;
      object-fit: contain;
      margin-right: 10px;
    }

    .nameVersion {
      font-size: 22px;
      font-weight: 500;
    }

    .corps {
      margin-top: 10px;
      font-size: 18px;
      letter-spacing: 0.6px;
      line-height: 28px;
      text-align: center;
    }

    .contactlink:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    .contactlink {
      margin-top: 6px;
      font-size: 18px;
      color: #3E51A9;
      font-weight: 500;
      letter-spacing: 0.3px;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
    }
  }

}
