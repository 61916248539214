.opusClient {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 68px;

  .littleTitle {
    margin-top: 100px;
    font-weight: 700;
    color: #4B5FB0;
    font-size: 18px;
  }

  .bigTitle {
    margin-top: 0px;
    text-align: center;
    font-weight: 600;
    font-size: 43px;
    margin-bottom: 120px;
  }

  .corpsPage {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    opacity: 0.5;
    line-height: 30px;
    margin-top: -70px;
    width: 600px;
    max-width: 88vw;
  }
}
