.opusPro {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;

  .triangleRectangle {

    .rectangle {
      width: 100vw;
      background-color: #F7F9FC;
      height: 450px;
    }

    .triangle {
      height: 30px;
      border-top: solid #F7F9FC 100px;
      border-right: solid rgba(123, 143, 222, 0) 100vw;
    }

  }

  .opusBlanc {
    margin-top: -450px;
    font-size: 300px;
    color: #FFFFFF;
    font-weight: 100;
  }

  .blueTitle {
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #625BF6;
  }

  .widgetTitle {
    max-width: 90vw;
    margin-top: 120px;
    align-items: center;
    width: 1100px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;

    .leftPart {
      width: 580px;
      max-width: 80vw;

      .title {
        margin-top: 20px;
        font-size: 35px;
        font-weight: 600;
        margin-left: 30px;
        width: 500px;
        max-width: 80vw;
      }

      .corps {
        font-size: 18px;
        line-height: 32px;
        color: #465364;
        font-weight: 500;
        width: 500px;
        margin-left: 30px;
        max-width: 80vw;
      }

    }

    .rightPart {

      img {
        width: 500px;
        height: 350px;
        object-fit: cover;
        border-radius: 5px;
        max-width: 80vw;
        margin-bottom: 15px;
        margin-top: 15px;

      }
    }
  }

  .itemEtape {
    display: flex;

    .textPart {
      width: 570px;
      max-width: 80vw;

      .etapeCount {
        color: #625BF6;
        font-weight: 700;
      }

      .titleEtape {
        margin-top: 20px;
        font-size: 33px;
        font-weight: 600;
        width: 440px;
        max-width: 80vw;
      }

      .subtitleEtape {
        font-size: 18px;
        line-height: 28px;
        color: #465364;
        font-weight: 500;
        margin-bottom: 40px;
        width: 500px;
        max-width: 85vw;
        margin-bottom: 0px;
      }


    }

    img {
      width: 450px;
      height: 300px;
      border-radius: 10px;
      object-fit: cover;
      max-width: 80vw;

    }

  }
}

.modalTelechagrementStores {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 800px)/2);
  width: 800px;
  height: 750px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .iphonesStores {
    width: 650px;
    object-fit: contain;
  }

}

