.perrotrichardHome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;

  .container {
    width: 1600px;
    height: 960px;
    margin-left: 60px;
    margin-right: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    max-height: 100vh;
    max-width: calc(100vw - 100px);

    .leftPart {
      width: 288px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 800px;

      .logoPR {
        width: 180px;
      }
    }

    .rightPart {

      width: 1152px;
      max-width: calc(100vw - 300px);
      height: 800px;
      display: flex;
      align-items: center;
      flex-direction: column;


      .img1 {
        width: 1152px;
        max-width: calc(100vw - 300px);
        object-fit: contain;
      }

    }
  }


}
