.imageTextComposant {
  margin-top: 150px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;

  .textPart {
    width: 430px;

    .titleTextPart {
      margin-top: 0px;
      font-size: 30px;
      font-weight: 600;
    }

    .secondTitle {
      font-weight: 700;
      font-size: 18px;
    }

    .corps {
      font-size: 18px;
      line-height: 28px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      width: 450px;
      margin-top: 1px;
      max-width: 85vw;
    }

    .button:hover {
      opacity: 0.7;
    }

    .button {
      margin-top: 30px;
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #3569B4;
      border-radius: 5px;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 10px;
      height: 45px;
      color: #3569B4;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  .phoneTextPart {

    .titleTextPart {
      margin-top: 0px;
      font-size: 35px;
      font-weight: 600;
      max-width: 90vw;
      text-align: start;
    }

    .secondTitle {
      font-weight: 700;
      font-size: 21px;
    }

    .corps {
      margin-top: 1px;
      font-size: 21px;
      line-height: 33px;
      color: #465364;
      font-weight: 500;
      margin-bottom: 40px;
      max-width: 85vw;
      text-align: justify;
    }

    .button:hover {
      opacity: 0.7;
    }

    .button {
      margin-top: 30px;
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #3569B4;
      border-radius: 5px;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
      height: 45px;
      color: #3569B4;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  .imagePart {
    width: 550px;
    max-width: calc(100vw - 800px);
    min-width: 400px;

    .image {
      min-height: 300px;
      max-height: calc(100vw - 900px);
      height: 400px;
      width: 500px;
      object-fit: cover;
      margin-bottom: 40px;

    }
  }

}

