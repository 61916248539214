
$font-1: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

$color-1: #61dafb;
$color-2: #FFFFFF;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-5: #000000;

html {
  overscroll-behavior-x: none;
}

body {
  margin: 0rem;
  font-family: $font-1;
  background-color: #ffffff;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
  width: 100vw;
  position: absolute;
}

.longTransition {
  transition: margin-top ease-in-out 0.8s;
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}

.hover:hover {
  opacity: 0.8;
  cursor: pointer;
}

.hover {
  transition: opacity 0.3s;
}

a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
}

a:visited {
  color: $color-3;
}

.fondChargement {
  position: absolute;
  margin-top: 100px;
  margin-left: 270px;
  overflow-x: scroll;
  width: calc( 100vw - 310px);
  height: calc( 100vh - 130.7px);
  min-height: 50px;
  max-height: 150px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;


  .grosChargement {

    object-fit: contain;
    width: 30px;
    margin-left: 0px;
    margin-top: 0px;
    transition-delay: 5s;

  }

  @keyframes rotate360 {
    from {
      width: 0px;
    }
    to { transform: rotate(360deg); }
  }
  .grosChargement { animation: 1s rotate360 infinite linear; }
}

.transitionOpacity {
  transition: opacity 0.2s ease-in-out;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.OverlayModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

.blueButton:hover {
  opacity: 0.7;
  cursor: pointer;

}

.mainTitle {
  font-family: "Helvetica Neue";
  color: #10243F;
  font-size: 70px;
  font-weight: 600;
  letter-spacing: -4px;
  width: 500px;
  max-width: 85vw;
  text-align: center;
  margin-bottom: 20px;
}

.littleMainTitle {
  font-family: "Helvetica Neue";
  color: #10243F;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -4px;
  width: 500px;
  max-width: 85vw;
  margin-bottom: 20px;
  text-align: center;
}

.blueButton {
  background-color: #5f74fc;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  font-weight: 600;
  color: #5f74fc;
  font-size: 15px;
  transition: opacity 0.2s ease-in-out;

  .leftBleu {
    margin-top: 2px;
    height: 14px;
    width: 12px;
    margin-left: 5px;
    object-fit: contain;
  }
}

.littleBlueButton:hover {
  opacity: 0.7;
  cursor: pointer;
}

.littleBlueButton {
  background-color: #5f74fc;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  font-weight: 600;
  color: #5f74fc;
  font-size: 17px;

  .leftBleu {
    margin-top: 3px;
    height: 16px;
    width: 12px;
    margin-left: 8px;
    object-fit: contain;
  }
}

.fonctionnalitesIphone {
  margin-top: -650px;
  width: 1330px;
  max-width: 90vw;
  margin-bottom: 300px;
}

.miroir {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.modalFonct {
  position: absolute;
  top: 0;
  width: 500px;
  height: 500px;
  margin-left: calc(50vw - 500px);
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: scroll;

  .cross {
    width: 30px;
    margin-top: 20px;
    margin-right: 20px;
  }

}
