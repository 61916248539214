.storiesPage {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;

  .opus {
    font-weight: 700;
    color: #4F66FB;
  }

  .title {
    margin-top: 0px;
    font-weight: 700;
    text-align: center;
    color: #4F66FB;
    font-size: 45px;
    letter-spacing: -2.5px;
  }

  .bookAFreeMeeting {
    margin-top: 30px;
    background-color: #F3F6F9;
    width: 1000px;
    min-width: 500px;
    border-radius: 30px;
    margin-bottom: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: height ease-in-out 0.5s;

    .profilPicture {
      height: 340px;
      width: 340px;
      margin-left: 30px;

    }

    .titleMeeting {
      font-weight: 700;
      letter-spacing: -1.5px;
      font-size: 33px;
      margin-top: 15px;
      margin-bottom: 25px;
    }

    .descriptionMeeting {
      font-size: 15px;
      line-height: 25px;
    }

    .discover:hover {
      opacity: 0.7;
    }
    .discover {
      margin-top: 30px;
      display: flex;
      align-items: center;
      color: #6671EB;
      font-weight: 700;
      cursor: pointer;
      transition: opacity ease-in-out 0.2s;

      .leftBleu {
        width: 8px;
        margin-left: 8px;
        object-fit: contain;
      }
    }
  }
}

.articleLink {

  display: flex;
  margin-bottom: 70px;
  margin-top: 30px;
  align-items: center;

  .img {
    width: 500px;
    height: 300px;
    border-radius: 20px;
    background-color: #6671EB;
    margin-right: 50px;
  }

  .textPart {
    width: 512px;


    .tagArticle {
      font-weight: 700;
      color: #6671EB;
      margin-top: 0px;
    }

    .titleArticle {
      font-weight: 700;
      letter-spacing: -1.5px;
      font-size: 33px;
      margin-top: 15px;
      margin-bottom: 25px;
    }

    .descriptionArticle {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .discover:hover {
    opacity: 0.7;
  }
  .discover {
    display: flex;
    align-items: center;
    color: #6671EB;
    font-weight: 700;
    margin-top: 10px;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;

    .leftBleu {
      width: 10px;
      margin-left: 8px;
      object-fit: contain;
    }
  }

}

.storyPage {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;

  .firstTextPart {
    max-width: calc(100vw - 100px);
    width: 1150px;

    .blueTitle {
      font-weight: 700;
      color: #6671EB;
    }

    .titleArticle {
      margin-top: 0px;
      font-weight: 700;
      font-size: 38px;
      margin-bottom: 0px;
    }

    .firstDescription {
      line-height: 25px;
    }

    .temps {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;

      .clock {
        width: 20px;
        margin-right: 10px;
      }
    }
  }

  .coverArticle {
    margin-top: 50px;
    max-width: calc(100vw - 100px);
    margin-bottom: 50px;
    width: 1200px;
  }

  .articlePart {
    max-width: calc(100vw - 100px);
    width: 850px;

    .titleArticlePart {
      font-weight: 700;
      font-size: 30px;
    }

    .corpsArticlePart {
      white-space: pre-line;
      line-height: 25px;
    }
  }

  .bookAFreeMeeting {
    margin-top: 30px;
    background-color: #F3F6F9;
    width: 1000px;
    min-width: 500px;
    border-radius: 30px;
    margin-bottom: 200px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: height ease-in-out 0.5s;

    .profilPicture {
      height: 340px;
      width: 340px;
      margin-left: 30px;

    }

    .titleMeeting {
      font-weight: 700;
      letter-spacing: -1.5px;
      font-size: 33px;
      margin-top: 15px;
      margin-bottom: 25px;
    }

    .descriptionMeeting {
      font-size: 15px;
      line-height: 25px;
    }

    .discover:hover {
      opacity: 0.7;
    }
    .discover {
      margin-top: 30px;
      display: flex;
      align-items: center;
      color: #6671EB;
      font-weight: 700;
      cursor: pointer;
      transition: opacity ease-in-out 0.2s;

      .leftBleu {
        width: 8px;
        margin-left: 8px;
        object-fit: contain;
      }
    }
  }

}
